var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$slots[("item." + _vm.filterKey)])?_vm._t(("item." + _vm.filterKey)):(_vm.type === 'text')?_c('v-text-field',{attrs:{"outlined":"","hide-details":"","dense":"","label":_vm.$t(_vm.label)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):(_vm.type === 'select')?_c('v-select',{attrs:{"multiple":_vm.multiple,"outlined":"","hide-details":"","dense":"","item-text":_vm.itemText,"item-value":_vm.itemValue,"label":_vm.$t(_vm.label)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):(_vm.type === 'date')?_c('date-range-picker',{attrs:{"dense":"","clearable":"","hide-details":"","max":new Date().toISOString().substr(0, 10),"outlined":"","label":_vm.$t(_vm.label)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):(_vm.type === 'checkbox')?_c('fast-select',{attrs:{"filter-key":_vm.filterKey,"values":_vm.values,"title":_vm.title},on:{"change":function($event){return _vm.changeValue()}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):(_vm.type === 'autocomplete')?_c('v-autocomplete',{attrs:{"multiple":_vm.multiple,"outlined":"","dense":"","hide-details":"","label":_vm.$t(_vm.label),"items":_vm.values.filter(
        function (item) { return item.alias !== 'check_beneficiary' &&
          item.alias !== 'checked_beneficiary' &&
          item.alias !== 'closed'; }
      ),"item-value":_vm.itemValue,"item-text":_vm.itemText},scopedSlots:_vm._u([(_vm.multiple)?{key:"selection",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"black--text caption"},[_vm._v(" (+"+_vm._s(((_vm.model.length - 1) + " " + (_vm.$t(_vm.label))))+") ")]):_vm._e()]}}:null],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }