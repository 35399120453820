
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import { Dictionary } from "vue-router/types/router";
import filters from "../config/filters.json";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";
import rules from "@/services/helpers/validation-rules";

import SettingsDialog from "../components/SettingsDialog.vue";
import canDeleteFields from "../config/order-delete.json";
import HeadersToggle from "@/modules/orders/components/HeadersToggle.vue";
import EditSpecialists from "@/modules/orders/components/EditSpecialists.vue";
import FilterField from "@/modules/orders/components/FilterField.vue";
import debounce from "lodash/fp/debounce";
import permissions from "@/modules/orders/config/actions-fields.json";
import actionButtons from "@/modules/orders/config/actions-buttons.json";
import ActionsModal from "@/modules/orders/components/ActionsModal.vue";

export default Vue.extend({
  name: "Requests",

  components: {
    FilterField,
    EditSpecialists,
    SettingsDialog,
    HeadersToggle,
    ActionsModal
  },

  mixins: [tablePagination, tableFilters],

  data: () => ({
    debounce,
    actionButtons,
    filters,
    canDeleteFields,
    rules,
    dialog: false as boolean,
    subdivisionDialog: false as boolean,
    newPaymentDialog: false as boolean,
    parseArrayParams,
    debounced: null as any,
    filter: {} as Dictionary<any>,
    headers: [] as Array<HeadersInterface>,
    visibleHeaders: [] as Array<HeadersInterface>,
    items: [] as Array<any>,
    loading: false as boolean,
    showMenu: false,
    x: 0,
    y: 0,
    selectedServices: [] as any,
    selects: {
      statuses: [] as Array<any>,
      languages: [
        { value: "ro", text: "RO" },
        { value: "en", text: "EN" },
        { value: "ru", text: "RU" }
      ] as Array<SelectComponentInterface>,
      documentTypes: [] as Array<SelectComponentInterface>,
      sources: [] as Array<string>,
      purposeOfUse: [] as Array<SelectComponentInterface>,
      goods: [] as Array<SelectComponentInterface>,
      branches: [] as Array<SelectComponentInterface>,
      subdivisions: [] as Array<SelectComponentInterface>,
      serviceTypes: [] as Array<SelectComponentInterface>
    },
    lang: store.getters["localization/getCurrent"],
    selectedItem: null as any,
    isUploadFile: false as boolean,
    isPrintDialog: false as boolean,
    isLoadedSelectsData: false as boolean,
    showConfirm: false as boolean,
    managerId: null as null | number,
    orderId: null as null | number,
    showEditSpecialists: false as boolean,
    selectedText: "" as string,
    marks: [] as Array<any>,
    menu: [] as Array<any>,
    loadingServices: false as boolean,
    newBranchModel: null as any,
    newSubdivisionModel: null as any,
    showPrintDialog: false as boolean,
    windowSize: {
      x: 0
    }
  }),

  watch: {
    showFilters: {
      immediate: true,
      handler() {
        if (!this.isLoadedSelectsData) {
          this.getSelectsData();
        }
      }
    },
    selectedServices(val, oldVal) {
      if (this.debounced) {
        this.debounced.cancel();
      }

      if (oldVal.length) {
        this.loadingServices = true;
      }

      this.debounced = this.debounce(500, () => {
        if (oldVal.length) {
          setTimeout(() => {
            this.loading = true;

            setTimeout(() => {
              this.setServicesFilter();
              this.loading = false;
              this.loadingServices = false;
            }, 2500);
          }, 1000);
        }
      });

      this.debounced();
    }
  },

  computed: {
    canSwitchService() {
      const allowedRoles = [
        "admin",
        "chairman",
        "vice_president",
        "subdivision_head",
        "specialist",
        "specialist_ata",
        "expert"
      ];
      return this.currentRole && allowedRoles.includes(this.currentRole.alias);
    },
    currentRole(): any {
      return this.$store.getters["user/currentRole"];
    },
    settingsModalLabel() {
      if (this.isUploadFile && !this.isPrintDialog) {
        return this.$t("orders.form.fields.upload_guarand");
      }
      if (!this.isUploadFile && !this.isPrintDialog) {
        return this.$t("orders.form.fields.confirm_tax");
      }
      return "";
    },
    availableFilters() {
      const commonItems: any = [];
      const allArrays = this.selectedServices.reduce(
        (accumulator: any, { alias }: any) => [
          ...accumulator,
          ...(this.filters as any)[alias]
        ],
        []
      );

      for (const item of allArrays) {
        if (
          this.numberOfAppearance(allArrays, item.value) ===
            this.selectedServices.length &&
          !commonItems.some(({ value }: any) => value === item.value)
        ) {
          commonItems.push(item);
        }
      }

      return commonItems;
    },
    user() {
      return this.$store.getters["user/info"];
    }
  },

  async beforeRouteEnter(from, to, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 20
        }
      };

      const [serviceTypes, response] = await Promise.all([
        API.serviceTypes().getListWithFilters(),
        API.orders().getData(params)
      ]);

      next(vm => {
        vm.selects.serviceTypes = serviceTypes;
        vm.setServerResponse(response);
        vm.setFilters(params.filter);
        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  async mounted() {
    this.onResize();
    await this.loadData();
  },

  methods: {
    setServicesFilter() {
      this.filter.service_types = this.selectedServices
        .map((item: any) => {
          const service = this.selects.serviceTypes.find(
            ({ id }: any) => id === item.id
          );
          return (service as any)?.id;
        })
        .filter((item: any) => item);
      this.loading = false;
      this.loadingServices = false;
      this.debounced.cancel();
      this.updateData();
    },
    setAllServices() {
      this.selectedServices = this.selects.serviceTypes.map(
        (item: any) => item
      );
    },
    numberOfAppearance(array: any, value: any): number {
      return array.reduce((count: number, item: any) => {
        if (value === item.value) {
          return count + 1;
        }

        return count;
      }, 0);
    },
    toggleSpecialists(event: boolean, orderId: any = null): void {
      if (orderId) {
        this.orderId = orderId;
      }
      this.showEditSpecialists = event;
    },
    canDeleteOrder(alias: string): boolean {
      return this.canDeleteFields.subject.includes(alias);
    },
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.orders().getData({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });

        await this.setServerResponse(response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
      this.showConfirm = false;
    },
    async deleteRow(id: number): Promise<void> {
      this.loading = true;
      try {
        await this.$API.orders().delete(id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);
      this.filter = this.parseArrayFilter(newFilters);
    },
    resetFilters(): void {
      this.filter = {
        service_types: this.filter.service_types
      };
      try {
        this.$router.push(
          `/${this.$route?.meta?.module}/page/${this.pagination.number}`
        );
        this.setFilters(this.filter);
        this.applyFilters();
        this.showFilters = false;
      } catch {
        Promise.resolve();
      }
    },
    can(action: string, subject: string): boolean {
      return (
        permissions.actions.includes(action) &&
        !permissions.subject.includes(subject)
      );
    },
    parseArrayFilter(filter: any): any {
      const arrayFilter = [
        "language",
        "source",
        "document_type",
        "client_type",
        "valid_period",
        "created_at"
      ];

      const integerFields = [
        "branches",
        "subdivisions",
        "service_types",
        "status",
        "country",
        "purposes"
      ];

      for (const key of Object.keys(filter)) {
        if ([...integerFields, ...arrayFilter].includes(key)) {
          if (filter[key] && !Array.isArray(filter[key])) {
            filter[key] = filter[key]?.split(",");

            if (integerFields.includes(key)) {
              filter[key] = filter[key].map((item: string) => Number(item));
            }
          }
        }
      }

      return filter;
    },
    setServerResponse({ items, headers }: any) {
      this.headers = [
        ...headers,
        {
          text: "",
          value: "actions",
          width: "80px",
          align: "center",
          sortable: false
        }
      ];
      this.changeHeaders();
      this.items = items.data.map((item: any) => {
        const matchingItem = this.marks.find(
          (mark: any) => mark.order_id === item.id
        );
        const descriptions: any = {
          NEW_ORDER: this.$t("orders.new-order"),
          UPDATE_ORDER: this.$t("orders.update-order"),
          CHANGE_ORDER_SPECIALIST: this.$t("orders.specialist-reassign"),
          DEADLINE_FOR_ISSUE: this.$t("orders.order-dead_line")
        };

        return {
          ...item,
          highlight: matchingItem
            ? {
                ...matchingItem,
                description: descriptions[matchingItem.event_type]
              }
            : null
        };
      });
      this.totalItems = items.total;
    },
    showContextMenu(e: any, item: any) {
      this.selectedItem = item.item;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = true;
    },
    async loadSubdivisions(): Promise<void> {
      try {
        this.selects.subdivisions = await this.$API
          .subdivisions()
          .getList({ filter: { branches: this.filter.branches } });
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async close(): Promise<void> {
      this.selectedItem = null;
      await this.loadData();
    },
    async openSettings(item: any): Promise<void> {
      this.selectedItem = item;
      this.showPrintDialog = true;
      await this.$store.dispatch("dialog/show");
    },
    setFilter(field: string) {
      if (typeof this.filter[field] === "string") {
        this.filter[field] = this.filter[field].split(",");
      }
    },
    async getSelectsData(): Promise<void> {
      const [
        statuses,
        purposeOfUse,
        branches,
        subdivisions,
        documentTypes
      ] = await Promise.all([
        this.$API.orders().getStatuses(),
        this.$API.purposeOfUse().getList(),
        this.$API.branches().getList(),
        this.$API.subdivisions().getList(),
        //todo fix this
        this.$API.orders().getCardTypes(1)
      ]);

      this.selects.statuses = statuses;
      this.selects.purposeOfUse = purposeOfUse;
      this.selects.subdivisions = subdivisions.items;
      this.selects.branches = branches.items;
      this.selects.documentTypes = documentTypes.items;

      this.isLoadedSelectsData = true;
    },
    changeHeaders() {
      const localStorageHeaders = localStorage.getItem("headers");

      if (!localStorageHeaders) return;
      this.visibleHeaders = this.headers.filter((item: any) =>
        localStorageHeaders.includes(item.value)
      );
    },
    canView(item: any, service: string): boolean {
      return (
        item &&
        (this.actionButtons as any)[item.service_type.alias] &&
        (this.actionButtons as any)[item.service_type.alias].includes(service)
      );
    },
    getStyles(item: any) {
      return item.highlight?.event_type;
    },
    makeAsRead(value: any) {
      this.items.find(
        (item: any) => item.id === value.id
      ).highlight.event_type = "checked";
      this.selectedItem.highlight.event_type = "checked";
    },
    async updateData(): Promise<void> {
      this.loading = true;
      await this.applyFilters();

      this.loading = false;
    },
    onResize() {
      this.windowSize = { x: window.innerWidth };
    },
    downloadAdvice(item: any) {
      if (!item.advice) {
        return;
      }
      const element = document.createElement("a");
      element.setAttribute("href", item.advice);
      element.setAttribute("target", "_blank");
      element.click();
    }
  }
});
