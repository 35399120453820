
import Vue from "vue";
import DateRangePicker from "@/components/form-components/DateRangePicker.vue";
import FastSelect from "@/components/form-components/FastSelect.vue";

export default Vue.extend({
  name: "FilterField",

  components: {
    FastSelect,
    DateRangePicker
  },

  props: {
    value: {
      required: true
    },
    values: {
      type: Array,
      required: false,
      default: () => [] as Array<any>
    },
    type: {
      type: String,
      required: true
    },
    label: {
      required: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    filterKey: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    itemText: {
      type: String,
      required: false,
      default: "text"
    },
    itemValue: {
      type: String,
      required: false,
      default: "value"
    }
  },

  data: () => ({
    model: null as any
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      }
    },
    model() {
      this.$emit("input", this.model);
    }
  },

  methods: {
    changeValue() {
      this.$emit("change", this.filterKey);
      this.$emit("input", this.model);
    }
  }
});
