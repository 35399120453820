
import Vue from "vue";
export default Vue.extend({
  name: "FastSelect",

  props: {
    value: {
      required: true
    },
    values: {
      type: Array,
      required: true
    },
    filterKey: {
      type: String,
      required: true
    },
    label: {
      required: false
    },
    title: {
      type: String,
      required: false
    }
  },

  data: () => ({
    model: null as any
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      }
    }
  },

  methods: {
    changeValue() {
      this.$emit("change", this.filterKey);
      this.$emit("input", this.model);
    }
  }
});
