
import Vue from "vue";
import store from "@/store";
import Print from "@/modules/orders/components/Print.vue";

export default Vue.extend({
  name: "SettingsDialog",

  components: { Print },

  props: {
    value: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    model: {} as any,
    confirmationFile: "" as string,
    isLoading: false as boolean,
    selectedTab: 0 as number
  }),

  computed: {
    show() {
      return store.getters["dialog/show"];
    }
  },

  methods: {
    close() {
      this.$store.dispatch("dialog/hide");
    }
  }
});
