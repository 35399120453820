
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";

import KothingEditor from "custom-kothing-editor";
import kothingConfig from "../config/editorConfig.json";
import plugins from "custom-kothing-editor/lib/plugins";
import "custom-kothing-editor/dist/css/kothing-editor.min.css";

export default Vue.extend({
  name: "OrderTemplate",

  props: {
    orderId: {
      type: Number,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    kothingConfig,
    headerImage: require("@/assets/images/aaij-print-template-header.png"),
    logo1: require("@/assets/images/logo_1.jpg"),
    logo2: require("@/assets/images/logo_2.jpg"),
    model: "" as string,
    templates: [] as Array<SelectComponentInterface>,
    errorMessages: {} as any,
    dialog: false as boolean,
    isLoading: false as boolean,
    selectedTemplate: "" as string,
    queryNumber: "" as string
  }),

  watch: {
    disabled: {
      immediate: true,
      handler() {
        if (!this.disabled) {
          setTimeout(() => {
            this.createEditor();
            this.setEditorsContent();
          }, 300);
        }
      }
    }
  },

  async mounted() {
    try {
      const { content } = await API.orders().getDocumentContent(this.orderId);
      const { order } = await API.orders().getForEdit(this.orderId);
      const templates = await API.documentTemplates().getList(2);

      this.model = content;
      this.templates = templates;
      this.queryNumber = order.number;
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      this.isLoading = true;
      try {
        const { content } = await this.$API
          .orders()
          .saveDocumentContent(this.orderId, {
            content: this.editor.getContents()
          });
        this.model = content;
        await this.$emit("save");
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.isLoading = false;
    },
    createEditor(): void {
      this.editor = KothingEditor.create("editor", {
        plugins: plugins,
        mode: "classic",
        ...this.kothingConfig
      });
    },
    async setEditorsContent(): Promise<void> {
      if (this.selectedTemplate) {
        this.model = this.selectedTemplate;
        this.toggle();
      }
      this.editor.setContents(this.model);
    },
    async getTemplate(event: number): Promise<void> {
      try {
        const response = await this.$API
          .documentTemplates()
          .show(event, this.orderId);

        this.selectedTemplate = response.content;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    toggle() {
      this.dialog = !this.dialog;
      this.selectedTemplate = "";
    },
    close() {
      this.$emit("save", this.selectedTemplate);
    }
  }
});
