import Vue from "vue";

export default Vue.extend({
  data: () => ({
    showFilters: false as boolean,
    activeFilters: 0 as number
  }),

  methods: {
    toggleFilters(): void {
      this.showFilters = !this.showFilters;
    },
    getAvailableFilters(filter: { [value: string]: any }) {
      const newFilters: { [value: string]: any } = {};

      for (const key in filter) {
        if (Array.isArray(filter[key]) && !filter[key].length) {
          continue;
        }
        // todo удвлить это и посмотреть как сделано на digicars в мвшинах
        if (filter[key]) {
          newFilters[key] = filter[key];
        }
      }

      return { ...newFilters };
    },
    async applyFilters(): Promise<void> {
      try {
        if (Object.keys(this.filter).length) {
          const filterString = new URLSearchParams(
            this.getAvailableFilters(this.filter) as any
          ).toString();
          await this.$router.push(
            `/${this.$route?.meta?.module}/page/1?${filterString}&`
          );
          this.pagination.number = 1;
          this.filter = this.getAvailableFilters(this.filter);
        }
        this.changeActiveFilters();

        await this.loadData();
      } catch {
        await Promise.resolve();
      }
    },
    changeActiveFilters() {
      this.activeFilters = Object.keys(this.filter).length;
    },
    async resetFilters(): Promise<void> {
      this.filter = {};
      try {
        await this.$router.push(
          `/${this.$route?.meta?.module}/page/${this.pagination.number}`
        );
        await this.applyFilters();
      } catch {
        Promise.resolve();
      }
    }
  }
});
