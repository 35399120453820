var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.serviceType === 'ata')?_c('v-row',_vm._l((_vm.pages),function(page){return _c('v-col',{key:page.alias,attrs:{"cols":page.cols,"sm":page.cols * 2,"lg":page.cols}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(_vm.$t(("orders.print." + (page.alias))))+" ")]),_c('v-card-text',{staticClass:"px-3 mt-3 py-0"},[_c('v-row',{staticClass:"pa-0"},_vm._l((page.parts),function(pagePart){return _c('v-col',{key:((page.alias) + "_" + (pagePart.alias)),staticClass:"py-0 px-1"},[_c('v-alert',{attrs:{"text":"","color":pagePart.color,"outlined":""}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(_vm.$t(("orders.print." + (pagePart.alias))))+" ")]),_c('v-btn',{staticClass:"close-icon",attrs:{"icon":""},on:{"click":function($event){return _vm.printPage(page.alias, pagePart.alias)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1)])],1)}),1)],1)],1)],1)}),1):_vm._e(),(_vm.serviceType === 'aaij')?_c('v-row',{staticClass:"py-1 d-flex align-center justify-center"},[_c('order-template',{attrs:{"order-id":_vm.orderId,"disabled":_vm.disabled},on:{"save":function($event){return _vm.toggleView()}}})],1):_vm._e(),(_vm.disabled)?_c('v-row',{staticClass:"mt-15"},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[(_vm.serviceType === 'aaij')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate"},'span',attrs,false),on),[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","text":"","color":"primary"},on:{"click":function($event){return _vm.toggleView()}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t("orders.buttons.edit_template"))+" ")],1)],1)]}}],null,false,2547087329)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("global_buttons.document_download_description")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate"},'span',attrs,false),on),[(_vm.serviceType === 'aaij')?_c('v-btn',{attrs:{"outlined":"","text":"","disabled":!_vm.disabled,"color":"success"},on:{"click":function($event){return _vm.printOrderDocument()}}},[_c('v-icon',[_vm._v("mdi-printer")]),_c('span',[_vm._v(_vm._s(_vm.$t("global_buttons.document_download")))])],1):_vm._e()],1)]}}],null,false,3723574370)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("global_buttons.document_download_description")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate"},'span',attrs,false),on),[_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","text":"","color":"primary"},on:{"click":function($event){return _vm.printOrder()}}},[_c('v-icon',[_vm._v("mdi-printer")]),_c('span',[_vm._v(_vm._s(_vm.$t("orders.buttons.print")))])],1)],1)]}}],null,false,625147293)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("orders.buttons.print_description")))])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }