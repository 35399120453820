
import Vue from "vue";
import OrderTemplate from "@/modules/orders/views/orderTemplate.vue";

export default Vue.extend({
  name: "Print",

  components: { OrderTemplate },

  props: {
    orderId: {
      type: Number,
      required: true
    },
    serviceType: {
      type: String,
      required: true
    }
  },

  data: () => ({
    pages: [
      {
        alias: "main",
        cols: 6,
        title: "Coperta",
        parts: [
          {
            alias: "front",
            color: "success",
            title: "Foaia de titlu"
          },
          {
            alias: "back",
            color: "success",
            title: "Lista generala"
          },
          {
            alias: "anex",
            color: "success",
            title: "Anexa listei"
          }
        ]
      },
      {
        alias: "cotor",
        cols: 6,
        title: "Cotor",
        parts: [
          {
            alias: "export_reimport",
            color: "warning",
            title: "Export/reimport"
          },
          {
            alias: "import_reexport",
            color: "",
            title: "Import/reexport"
          },
          {
            alias: "transit",
            color: "blue",
            title: "Transit"
          }
        ]
      },
      {
        alias: "volet_export",
        cols: 6,
        title: "Volet export",
        parts: [
          {
            alias: "front",
            color: "warning",
            title: "Foaia de titlu"
          },
          {
            alias: "back",
            color: "warning",
            title: "Lista generala"
          },
          {
            alias: "anex",
            color: "warning",
            title: "Anexa listei"
          }
        ]
      },
      {
        alias: "volet_reimport",
        cols: 3,
        title: "Volet reimport",
        parts: [
          {
            alias: "front",
            color: "warning",
            title: "Foaia de titlu"
          }
        ]
      },
      {
        alias: "volet_import",
        cols: 3,
        title: "Volet import",
        parts: [
          {
            alias: "front",
            color: "",
            title: "Foaia de titlu"
          }
        ]
      },
      {
        alias: "volet_reexport",
        cols: 3,
        title: "Volet reexport",
        parts: [
          {
            alias: "front",
            color: "",
            title: "Foaia de titlu"
          }
        ]
      },
      {
        alias: "volet_transit",
        cols: 3,
        title: "Volet tranzit",
        parts: [
          {
            alias: "front",
            color: "blue",
            title: "Foaia de titlu"
          }
        ]
      },
      {
        alias: "last",
        cols: 6,
        title: "Ultima foaie",
        parts: [
          {
            alias: "rules",
            color: "success",
            title: "Reguli de utilizare"
          },
          {
            alias: "associations",
            color: "success",
            title: "Asociatii garante"
          }
        ]
      }
    ] as Array<any>,
    headerImage: require("@/assets/images/aaij-print-template-header.png"),
    logo1: require("@/assets/images/logo_1.jpg"),
    logo2: require("@/assets/images/logo_2.jpg"),
    selectedTab: 0 as number,
    disabled: true as boolean
  }),

  methods: {
    async printPage(pageAlias: string, pagePart: string) {
      let newPageAlias = pageAlias;

      if (pageAlias.startsWith("volet")) {
        newPageAlias = pageAlias.replace("_", "/");
      }

      window.open(
        `${process.env.VUE_APP_API_SERVER_URL}/orders/${this.orderId}/pdf/${newPageAlias}/${pagePart}`,
        "_blank"
      );
    },
    async printOrder(): Promise<void> {
      try {
        const file = await this.$API.orders().printOrder(this.orderId);
        this.downloadFile(file);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    close() {
      this.$emit("close");
    },
    downloadFile(file: any) {
      const element = document.createElement("a");
      element.setAttribute("href", file.url);
      element.setAttribute("target", "_blank");
      element.click();
    },
    async printOrderDocument() {
      try {
        const data = await this.$API.orders().printAaijOrder(this.orderId);
        if (data) {
          this.downloadFile(data);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    toggleView() {
      this.disabled = !this.disabled;
    }
  }
});
