export const getFormattedTime = (time: number): string => {
  if (!time) {
    return "";
  }
  const formatter = new Intl.DateTimeFormat("ru", { month: "short" });
  const date = new Date(time * 1000);

  return `${date.getDate()} ${formatter.format(date)}`;
};

export const parseArrayParams = (params: object): { [value: string]: any } => {
  const parsedParams: { [value: string]: any } = { ...params };
  for (const key in parsedParams) {
    if (Array.isArray(parsedParams[key])) {
      parsedParams[key] = parsedParams[key].join(",");
    }
  }
  return parsedParams;
};
