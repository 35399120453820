
import Vue from "vue";

import actionButtons from "../config/actions-buttons.json";
import canDeleteFields from "../config/order-delete.json";

export default Vue.extend({
  name: "ActionsModal",

  props: {
    value: {
      required: false
    },
    item: {
      required: true,
      default: () => ({} as any)
    },
    fullSize: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    actionButtons,
    canDeleteFields,
    actions: {} as any,
    selectedText: "" as string,
    showEditSpecialists: false as boolean
  }),

  computed: {
    disabled() {
      return !this.canView("print");
    },
    showEdit(): boolean {
      return ["draft", "processing"].includes(this.item?.status?.alias);
    },
    displayPrint() {
      return (
        this.item.service_type.alias === "ata" ||
        this.item.service_type.alias === "cert"
      );
    }
  },

  mounted() {
    document.getElementById("table")?.addEventListener("mousedown", () => {
      this.getSelectedText();
    });
    document.getElementById("table")?.addEventListener("mouseup", () => {
      this.doSomethingWithSelectedText();
    });
  },

  beforeDestroy() {
    clearTimeout();
  },

  methods: {
    canView(field: string): boolean {
      return (
        (this.actionButtons as any)[this.item.service_type.alias] &&
        (this.actionButtons as any)[this.item.service_type.alias].includes(
          field
        )
      );
    },
    canDeleteOrder(alias: string): boolean {
      return this.canDeleteFields.subject.includes(alias);
    },
    getSelectedText() {
      let text = "";
      if (typeof window.getSelection !== undefined) {
        text = (window as any).getSelection().toString();
      } else if (
        typeof (document as any).selection !== undefined &&
        (document as any).selection.type === "Text"
      ) {
        text = (document as any).selection.createRange().text;
      }
      return text;
    },
    doSomethingWithSelectedText() {
      this.selectedText = this.getSelectedText();
    },
    async copyToClipboard(text: string) {
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      await this.$store.dispatch(
        "alert/showInfo",
        `${this.$t("global_validation.copied-to-clipboard")}`
      );
    },
    async openSettings(item: any): Promise<void> {
      this.$emit("open", item);
    },
    openAssistants(item: any) {
      this.showEditSpecialists = true;
      this.$emit("change", this.showEditSpecialists, item.id);
      this.orderId = item.id;
    },
    async makeAsRead() {
      try {
        await this.$API.orders().read({ order_id: (this.item as any).id });
        this.$emit("read", this.item);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async deleteRow(id: number): Promise<void> {
      this.loading = true;
      try {
        await this.$API.orders().delete(id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        this.$emit("update");
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    downloadFile(file: any) {
      if (!file.url) {
        return;
      }
      const element = document.createElement("a");
      element.setAttribute("href", file.url);
      element.setAttribute("target", "_blank");
      element.click();
    },
    downloadAdvice(file: any) {
      if (!file.advice) {
        return;
      }
      const element = document.createElement("a");
      element.setAttribute("href", file.advice);
      element.setAttribute("target", "_blank");
      element.click();
    },
    async printOrder(): Promise<void> {
      try {
        const file = await this.$API.orders().printOrder(this.item.id);
        this.downloadFile(file);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async cloneOrder(): Promise<void> {
      try {
        await this.$API.orders().cloneOrder(this.item.id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("orders.modals.alert.successful_cloned")
        );
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
