
import Vue from "vue";

export default Vue.extend({
  name: "HeadersToggle",

  props: {
    headers: {
      required: true,
      type: Array
    }
  },

  data: () => ({
    items: [] as Array<any>,
    panels: {} as any,
    panel: [0, 1] as Array<number>,
    disabled: false as boolean,
    readonly: false as boolean,
    allVisible: false as boolean
  }),

  watch: {
    headers: {
      immediate: true,
      handler() {
        this.getFromStorage();
      }
    },
    items: {
      deep: true,
      handler() {
        localStorage.setItem("headers", JSON.stringify(this.items));
        this.$emit("changeHeaders");
      }
    }
  },

  methods: {
    someHeadersVisible(): boolean {
      if (!this.items) return false;
      let state = false;
      if (this.headers.every((item: any) => this.items.includes(item.value))) {
        state = false;
        this.allVisible = true;
      } else {
        this.allVisible = false;
      }

      return state;
    },
    changeCheckboxState(): void {
      this.allVisible = !this.allVisible;
      if (!this.items) return;

      if (this.allVisible) {
        this.items = this.headers.map((item: any) => item.value);
      } else {
        this.items = [];
      }
    },
    getFromStorage(): void {
      if (!localStorage.getItem("headers")) {
        this.items = this.headers.map((item: any) => item.value);
      } else {
        this.items = JSON.parse(localStorage.getItem("headers")!);

        this.items = this.items.map((item: any) => {
          if (this.headers.some((el: any) => el.value === item)) {
            return item;
          }
        });
      }
    },
    openExpansionPanel(value: string) {
      this.panels[value] = !this.panels[value];
    },
    toggleVisible(item: any): void {
      if (this.items.some((value: string) => value === item.value)) {
        this.items = this.items.filter((value: string) => value !== item.value);
      } else {
        this.items.push(item.value);
      }
      localStorage.setItem("headers", JSON.stringify(this.items));
    }
  }
});
